import React from "react";
import p1 from "../images/WhatsApp Image 2024-01-14 at 10.43.37 AM.jpeg";
import logo from "../images/colors.png";

export default function AboutUSHome() {
  return (
    <>
      <div className="container">
        <div className="title text-center py-5">
          <h2 className="text-primary  ">About Us</h2>
          <div className="hhh"></div>

          {/* <h3 className="translate-middle">A</h3>
//<div className="afterr"></div> */}
        </div>
        <div id="Aboutt" className="row ">
          <div className="col-md-5 p-4 d-flex">
            <img
              className="w-100 align-content-center justify-content-center "
              style={{ height: 420 }}
              src={logo}
              alt="markting"
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 p-4">
            <h3 className="text-primary">Who we are?</h3>
            <p>
              Etqan for Geoinformatics Systems and Solutions is a leading
              specialized company that provides spatial systems and solutions,
              geospatial services, and geoinformatics. With a remarkable track
              record of major projects and collaborations with prominent
              international organizations, Etqan has established itself as a
              trusted partner in delivering innovative and sustainable
              geospatial solutions.{" "}
            </p>
            <div className="about-p">
              <p>
                Our collaboration with UNICEF in Yemen stands as a testament to
                our expertise. We have successfully provided innovative spatial
                solutions and geospatial consultations in various projects,
                including areas such as 22 May and Al-Salakhana. These
                initiatives have contributed to enhancing the understanding of
                spatial dynamics and supporting effective decision-making.{" "}
              </p>
              <p>
                Excitingly, we are currently preparing for a new cooperation
                framework with the FAO, which will be announced in the near
                future. This partnership will further strengthen our commitment
                to delivering cutting-edge spatial solutions and advancing
                geoinformatics practices.
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </>
  );
}
