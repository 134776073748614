import React from "react";
import Slider from "react-slick";
// import p11 from "../images/p11.jpg";
// import p12 from "../images/p12.png";
// import p13 from "../images/p13.png";
// import p14 from "../images/p14.png";
// import p15 from "../images/p15.png";
// import p16 from "../images/p16.png";
// import p17 from "../images/p17.jpg";
// import p18 from "../images/p18.png";
// import p19 from "../images/p19.png";
// import p20 from "../images/p20.png";
import p21 from "../images/p25.jpg";
import p22 from "../images/p24.png";
import p23 from "../images/partner2.jpeg";

export default function PartnersHome() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div className="container my-5">
        <h2 className="text-primary text-center "> Our Success Partners </h2>
        <Slider {...settings}>
          <div>
            <a target="_blank" href="https://globeimagine.com/">
              <img className="w-100" style={{ height: "200px" }} src={p23} />{" "}
            </a>
          </div>
          {/* <div>
            <img className="w-100" style={{ height: "200px" }} src={p11} />{" "}
          </div>
          <div>
            <img className="w-100" style={{ height: "200px" }} src={p14} />{" "}
          </div>
          <div>
            <img className="w-100 " style={{ height: "200px" }} src={p12} />{" "}
          </div>
          <div>
            <img className="w-100 " style={{ height: "200px" }} src={p15} />{" "}
          </div>
          <div>
            <img className="w-100" style={{ height: "200px" }} src={p17} />{" "}
          </div>
          <div>
            <img className="w-100" style={{ height: "200px" }} src={p18} />{" "}
          </div>
          <div>
            <img className="w-100" style={{ height: "200px" }} src={p19} />{" "}
          </div>
          <div>
            <img className="w-100" style={{ height: "200px" }} src={p20} />{" "}
          </div> */}
          <div>
            <a target="_blank" href="https://www.atign.tn/">
              <img className="w-100 " style={{ height: "200px" }} src={p21} />{" "}
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.hccd-construction.com/SubComp_a.aspx?id=03"
            >
              <img className="w-100 " style={{ height: "200px" }} src={p22} />{" "}
            </a>
          </div>
        </Slider>
      </div>
    </>
  );
}
