import React from "react";
import emailjs from "@emailjs/browser";
import { forwardRef, useRef } from "react";

export default function ContactUsHome() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);
    emailjs
      .sendForm(
        "service_a5o8fhf",
        "template_ve3zjve",
        form.current,
        "PXEI6l5xm1gLToZhk"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="container ">
        <div id="contactus" className=" title  text-center py-5">
          <h2 className="text-primary">Contact Us</h2>
          <div className="hhh"></div>
        </div>
        <h3 className="text-center text-primary">
          {" "}
          Etqan has established itself as a trusted partner in delivering
          innovative and sustainable geospatial solutions.
        </h3>
        <br />
        <div className="row">
          <div className="col-6">
            <h3>Get answers and advices from professional consultants</h3>
            <ul className="fa-ul fa-1x mt-1 ">
              <li>
                <span className="fa-li">
                  <i className="fa-regular fa-map mt-2"></i>
                </span>
                <h4>Address</h4>
              </li>
              <li>
                <span className="fa-li">
                  <i className="fa-regular fa-mobile-screen mt-2"></i>
                </span>
                <h4>Phone</h4>
              </li>
              <li>
                <span className="fa-li">
                  <i className="fa-regular fa-envelope mt-2"></i>
                </span>
                <h4> Say Hello</h4>
              </li>
            </ul>
          </div>
          <div className="col-6">
            <form id="s" ref={form} onSubmit={sendEmail}>
              <label for="Name">Name</label>
              <input
                type="text"
                className="form-control mb-3 text-primary"
                id="Name"
                placeholder="Name"
                name="user_name"
              />

              <label for="Email">Email</label>
              <input
                type="text"
                className="form-control mb-3 text-primary"
                id="Email"
                placeholder="Email"
                name="user_email"
              />

              <label for="Phone">Phone</label>
              <input
                type="text"
                className="form-control mb-3 text-primary"
                id="Phone"
                placeholder="Phone"
                name="user_phone"
              />

              <label for="Message">Message</label>
              <textarea
                className="form-control mb-3 text-primary"
                id="Message"
                placeholder="Message"
                name="message"
              ></textarea>

              <button
                type="submit"
                value="Send"
                className="btn btn-outline-primary"
              >
                <i className="fa-regular fa-paper-plane">send message</i>
              </button>
            </form>
          </div>
        </div>
      </div>

      <br />
      <br />
    </>
  );
}
