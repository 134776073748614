import React from "react";
import team1 from "../images/me1.jpeg";
import team2 from "../images/nnnn.jpg";
import team3 from "../images/tokakamal.jpg";
import team9 from "../images/seiif.jpg";
import team4 from "../images/merii.jpeg";
import team5 from "../images/Aicha.jpg";
import team6 from "../images/barakart.jpg";
import team7 from "../images/megoo.jpeg";
import team8 from "../images/hiiima.jpeg";

export default function OurTeamHome() {
  return (
    // <>
    //   {
    //     <div className="container bg-light">
    //       <section className="ourTeam">
    //         <div className="title text-center py-5">
    //           <h2 className="text-primary">Our Team</h2>
    //         </div>
    //         <div className="row">
    //           <div id="carouselExampleCaptions" className="carousel slide">
    //             <div className="carousel-indicators">
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="0"
    //                 className="active"
    //                 aria-current="true"
    //                 aria-label="Slide 1"
    //               ></button>
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="1"
    //                 aria-label="Slide 2"
    //               ></button>
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="2"
    //                 aria-label="Slide 3"
    //               ></button>
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="3"
    //                 aria-label="Slide 4"
    //               ></button>
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="4"
    //                 aria-label="Slide 5"
    //               ></button>
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="5"
    //                 aria-label="Slide 6"
    //               ></button>
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="6"
    //                 aria-label="Slide 7"
    //               ></button>
    //               <button
    //                 type="button"
    //                 data-bs-target="#carouselExampleCaptions"
    //                 data-bs-slide-to="7"
    //                 aria-label="Slide 8"
    //               ></button>
    //             </div>
    //             <div className="carousel-inner">
    //               <div className="carousel-item active">
    //                 <div className="coursalImg d-flex  justify-content-center text-center align-items-centerd-flex  justify-content-center text-center align-items-center ">
    //                   <img
    //                     src={team9}
    //                     className="d-block  w-75 h-100 "
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Seif Elobeidi</h5>
    //                   <p>Project Manager</p>
    //                 </div>
    //               </div>
    //               <div className="carousel-item">
    //                 <div className="coursalImg d-flex justify-content-center text-center align-items-center">
    //                   <img
    //                     src={team1}
    //                     className="d-block  w-75  h-100"
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Ahmed Amr</h5>
    //                   <p>Head of Full-Stack Development</p>
    //                 </div>
    //               </div>
    //               <div className="carousel-item ">
    //                 <div className="coursalImg d-flex  justify-content-center text-center align-items-center  ">
    //                   <img
    //                     src={team6}
    //                     className="d-block w-75 h-100"
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Ahmed Barakat</h5>
    //                   <p>GIS Data Analyst</p>
    //                 </div>
    //               </div>
    //               <div className="carousel-item">
    //                 <div
    //                   className="coursalImg d-flex  justify-content-center text-center align-items-center"
    //                   d-flex
    //                   justify-content-center
    //                   text-center
    //                   align-items-center
    //                 >
    //                   <img
    //                     src={team5}
    //                     className="d-block  w-75  h-100"
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Aicha Boukhobza</h5>
    //                   <p>Geomatics Engineer</p>
    //                 </div>
    //               </div>
    //               <div className="carousel-item">
    //                 <div
    //                   className="coursalImg d-flex  justify-content-center text-center align-items-center "
    //                   d-flex
    //                   justify-content-center
    //                   text-center
    //                   align-items-center
    //                 >
    //                   <img
    //                     src={team2}
    //                     className="d-block  w-75  h-100"
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Mary Morris</h5>
    //                   <p>GIS Business Analyst</p>
    //                 </div>
    //               </div>
    //               <div className="carousel-item">
    //                 <div
    //                   className="coursalImg d-flex  justify-content-center text-center align-items-center"
    //                   d-flex
    //                   justify-content-center
    //                   text-center
    //                   align-items-center
    //                 >
    //                   <img
    //                     src={team3}
    //                     className="d-block  w-75  h-100"
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Toka kamal</h5>
    //                   <p>Pre-sales Engineer</p>
    //                 </div>
    //               </div>
    //               <div className="carousel-item">
    //                 <div
    //                   className="coursalImg d-flex  justify-content-center text-center align-items-center"
    //                   d-flex
    //                   justify-content-center
    //                   text-center
    //                   align-items-center
    //                 >
    //                   <img
    //                     src={team8}
    //                     className="d-block  w-75  h-100"
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Ibrahim Ashraf</h5>
    //                   <p>Solution Engineer</p>
    //                 </div>
    //               </div>
    //               <div className="carousel-item">
    //                 <div
    //                   className="coursalImg d-flex  justify-content-center text-center align-items-center"
    //                   d-flex
    //                   justify-content-center
    //                   text-center
    //                   align-items-center
    //                 >
    //                   <img
    //                     src={team7}
    //                     className="d-block  w-75  h-100"
    //                     alt="..."
    //                   />
    //                 </div>
    //                 <div className="carousel-caption d-none d-md-block">
    //                   <h5>Mohamed Magdy</h5>
    //                   <p>Front End Developer</p>
    //                 </div>
    //               </div>
    //             </div>
    //             <button
    //               className="carousel-control-prev"
    //               type="button"
    //               data-bs-target="#carouselExampleCaptions"
    //               data-bs-slide="prev"
    //             >
    //               <span
    //                 className="carousel-control-prev-icon"
    //                 aria-hidden="true"
    //               ></span>
    //               <span className="visually-hidden">Previous</span>
    //             </button>
    //             <button
    //               className="carousel-control-next"
    //               type="button"
    //               data-bs-target="#carouselExampleCaptions"
    //               data-bs-slide="next"
    //             >
    //               <span
    //                 className="carousel-control-next-icon"
    //                 aria-hidden="true"
    //               ></span>
    //               <span className="visually-hidden">Next</span>
    //             </button>
    //           </div>
    //         </div>
    //       </section>
    //     </div>
    //   }
    // </>
    <>
      <div class="container text-center ">
        <div class="section-title text-center">
          <h2 className=" text-primary">Our Team</h2>
        </div>
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators position-static d-flex align-items-center">
            <div class="indicator-item">
              <img
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class=" rounded-circle w-100"
                aria-current="true"
                aria-label="Slide 1"
                src={team9}
                alt=""
                srcset=""
              />
              <div class="client-info">
                <h3>Seif Elobeidi</h3>
                <p>Project Manager</p>
              </div>
            </div>
            <div class="indicator-item">
              <img
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                class="rounded-circle w-100 active "
                aria-current="true"
                aria-label="Slide 2"
                src={team1}
                alt=""
                srcset=""
              />
              <div class="client-info">
                <h3>Ahmed Amr</h3>
                <p>Head of Full-Stack Development</p>
              </div>
            </div>

            <div class="indicator-item">
              <img
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                class="rounded-circle w-100  "
                aria-current="true"
                aria-label="Slide 3"
                src={team6}
                alt=""
                srcset=""
              />
              <div class="client-info">
                <h3>Ahmed Barakat</h3>
                <p>GIS Data Analyst</p>
              </div>
            </div>
            <div class="indicator-item">
              <img
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="5"
                class="rounded-circle w-100 "
                aria-current="true"
                aria-label="Slide 6"
                src={team8}
                alt=""
                srcset=""
              />
              <div class="client-info">
                <h3>Ibrahim Ashraf</h3>
                <p>Solution Engineer</p>
              </div>
            </div>
            <div class="indicator-item">
              <img
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                class="rounded-circle w-100 "
                aria-current="true"
                aria-label="Slide 4"
                src={team4}
                alt=""
                srcset=""
              />
              <div class="client-info">
                <h3>Mary Morris</h3>
                <p>GIS Business Analyst</p>
              </div>
            </div>
            <div class="indicator-item">
              <img
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="6"
                class="rounded-circle w-100 "
                aria-current="true"
                aria-label="Slide 7"
                src={team3}
                alt=""
                srcset=""
              />
              <div class="client-info">
                <h3>Toka kamal</h3>

                <p>Pre-sales Engineer</p>
              </div>
            </div>
            <div class="indicator-item">
              <img
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="4"
                class="rounded-circle w-100 "
                aria-current="true"
                aria-label="Slide 5"
                src={team5}
                alt=""
                srcset=""
              />
              <div class="client-info">
                <h3>Aicha Boukhobza</h3>
                <p>Geomatics Engineer</p>
              </div>
            </div>
          </div>

          <div class="carousel-inner lag ">
            <div class="carousel-item">
              <div class="single-box"></div>
            </div>
            <div class="carousel-item">
              <div class="single-box"></div>
            </div>
            <div class="carousel-item">
              <div class="single-box"></div>
            </div>
            <div class="carousel-item">
              <div class="single-box"></div>
            </div>
            <div class="carousel-item active">
              <div class="single-box"></div>
            </div>
            <div class="carousel-item">
              <div class="single-box"></div>
            </div>
            <div class="carousel-item">
              <div class="single-box"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
