import "./App.css";
import Home from "./Home/Home";
import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from "react-router-dom";
import Layout from "./Layout/Layout";
import NotFound from "./NotFound/NotFound";
import AboutUs from "./Aboutus/AboutUs";
import Partners from "./Partners/Partners";
import Projects from "./Projects/Projects";
import Trainings from "./Trainings/Trainings";
import Services from "./Services/Services.jsx";
import Service4 from "./Services/Service4.jsx";
import Service1 from "./Services/Service1.jsx";
import Service2 from "./Services/Service2.jsx";
import Service3 from "./Services/Service3.jsx";

function App() {
  const routers = createHashRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        { path: "home", element: <Home /> },
        { path: "aboutus", element: <AboutUs /> },
        { path: "projects", element: <Projects /> },
        { path: "trainings", element: <Trainings /> },
        { path: "service1", element: <Service1 /> },
        { path: "service2", element: <Service2 /> },
        { path: "service3", element: <Service3 /> },
        { path: "service4", element: <Service4 /> },
        { path: "partners", element: <Partners /> },
        { path: "", element: <Home /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={routers}></RouterProvider>
    </>
  );
}

export default App;
