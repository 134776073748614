import img1 from "../images/Project Management Services/Project Planning and Scope Definition.jpg";
import img2 from "../images/Project Management Services/Stakeholder Management.jpg";
import img3 from "../images/Project Management Services/Resource Allocation and Management.jpg";
import img4 from "../images/Project Management Services/Risk Management.jpg";
import img5 from "../images/Project Management Services/Project Monitoring and Reporting.png";
import img6 from "../images/Project Management Services/Change Management.png";
import img7 from "../images/Project Management Services/Project Documentation.png";
import { Helmet } from "react-helmet";

export default function Service2() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Etqan Service </title>
        <link rel="canonical" href="https://etqan-egy.com/service" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img1}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              1.Project Planning and Scope Definition:
            </h2>
            <p>
              {" "}
              We work closely with our clients to understand their project
              requirements, goals, and constraints. Our project management team
              assists in defining the project scope, objectives, deliverables,
              and timelines. We develop a detailed project plan that outlines
              the tasks, milestones, resources, and dependencies to ensure a
              clear roadmap for project execution
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">2.Stakeholder Management:</h2>
            <p>
              {" "}
              Effective stakeholder management is vital for project success. We
              help identify key stakeholders, define their roles and
              responsibilities, and establish communication channels to ensure
              regular and transparent communication throughout the project
              lifecycle. Our team ensures that stakeholders are engaged and
              informed, fostering collaboration and alignment towards project
              goals
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img2}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img3}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              3.Resource Allocation and Management:
            </h2>
            <p>
              {" "}
              We assist in resource allocation, including human resources,
              equipment, and technology, to ensure that the project has the
              necessary capabilities and expertise. Our project management team
              optimizes resource utilization, monitors resource availability,
              and manages resource conflicts to ensure efficient project
              execution.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">4.Risk Management:</h2>
            <p>
              {" "}
              Identifying and mitigating risks is crucial for project success.
              We conduct a comprehensive risk assessment to identify potential
              risks and develop mitigation strategies. Our team monitors risks
              throughout the project lifecycle, implementing proactive measures
              to minimize their impact. By effectively managing risks, we help
              our clients anticipate challenges and ensure project continuity.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img4}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img5}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              5.Project Monitoring and Reporting:
            </h2>
            <p>
              {" "}
              We closely monitor project progress, tracking key performance
              indicators and milestones. Our project management team provides
              regular status updates and progress reports, ensuring transparency
              and accountability. We use project management tools and techniques
              to track project tasks, timelines, and resource utilization,
              allowing for effective project monitoring and decision-making.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">6. Change Management:</h2>
            <p>
              {" "}
              In the dynamic field of geospatial projects, change is often
              inevitable. We employ change management strategies to effectively
              address changes in project requirements, scope, or timelines. Our
              team assesses the impact of changes, communicates changes to
              stakeholders, and implements appropriate change control procedures
              to minimize disruptions and maintain project momentum.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img6}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img7}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">7.Project Documentation:</h2>
            <p>
              {" "}
              We ensure comprehensive documentation throughout the project
              lifecycle. This includes project plans, requirements
              specifications, design documents, test cases, and user manuals. By
              maintaining wellorganized and up-to-date project documentation, we
              facilitate knowledge transfer, support future maintenance, and
              ensure project continuity.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
