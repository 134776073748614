import React from "react";
import team2 from "../images/kikoo.jpeg";
import p1 from "../images/WhatsApp Image 2024-01-14 at 10.43.37 AM.jpeg";
import { Helmet } from "react-helmet";

export default function Projects() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Etqan Projects </title>
        <link rel="canonical" href="https://etqan-egy.com/projects" />
      </Helmet>
      <br />
      <br />
      <div className="container">
        <div>
          <h2 className="text-primary">
            1- UNICEF and Mohamed Ali Zayed for General contracting and
            engineering consulting
          </h2>
          <h5>
            We are delighted to collaborated with UNICEF on a Geographic
            Information Systems (GIS) and Information Technology Solutions
            project in Yemen. Our project focused on the development of water
            and sanitation networks in the municipalities of Al-Salakhana, May
            22, Dar Al-Salam, and Al-Bayadiya.
          </h5>
          <h5>
            The primary objective of the project was to modernize and enhance
            the efficiency of the existing infrastructure by updating maps,
            charts, and databases. Additionally, we provided a range of
            engineering and urban planning consultations to optimize the
            networks. The utilization of Building Information Modeling (BIM)
            technology enabled accurate assessments of buildings, water plants,
            and sewage systems.
          </h5>
          <h3>Benefits and Returns:</h3>
          <h5>
            - Infrastructure Improvement: The project successfully upgraded
            water and sanitation networks, delivering improved services to the
            local communities in Al-Salakhana, May 22, Dar Al-Salam, and
            Al-Bayadiya.
            <br />
            - Accurate and Up-to-Date Information: The updated maps, charts, and
            databases provided precise information for effective decision-making
            and planning purposes.
            <br />
            - Enhanced Efficiency: By optimizing network efficiency, the project
            reduced water and resource wastage, resulting in cost savings and
            environmental benefits.
            <br />
            - Sustainable Development: The project aligned with the goals of
            sustainable development by ensuring the long-term sustainability and
            effectiveness of water and sanitation systems.
            <br />
            We are proud to have contributed to the advancement of water and
            sanitation infrastructure in Yemen through our collaboration with
            UNICEF. This project exemplifies our expertise in GIS and IT
            solutions and reinforces our commitment to delivering professional
            and impactful initiatives.
            <br />
          </h5>
        </div>
        <div>
          <h2 className="text-primary">2- Madaba Agricultural Directorate</h2>
          <h5>
            We are glad to present our highly professional project in the field
            of Geographic Information Systems (GIS) and Information Technology
            Solutions. Our project focuses on updating and developing olive
            cultivation lands and conducting a comprehensive inventory of their
            locations in Madaba, Jordan, on behalf of the Directorate of
            Agriculture.
          </h5>
          <h5>
            Our target of the project is to update maps, diagrams, and databases
            related to olive cultivation lands. Additionally, we provide various
            engineering and urban planning consultations to optimize the
            utilization of these lands. The project also involves evaluating the
            quality and health of different plant species.
          </h5>
          <h3>Benefits and Returns:</h3>
          <h5>
            - Improved Land Management: Our solutions enable effective
            management and utilization of olive cultivation lands in Madaba,
            Jordan, resulting in increased productivity and sustainability.
            <br />
            - Accurate and Updated Information: Our updated maps, diagrams, and
            databases provide precise and reliable information about the olive
            cultivation lands, facilitating informed decision-making and
            planning.
            <br />
            - Enhanced Land Use Efficiency: Our consultations and expertise in
            engineering and urban planning contribute to optimizing the
            utilization of the lands, ensuring efficient and productive
            agricultural practices.
            <br />
            - Evaluation of Plant Quality and Health: By assessing the quality
            and health of different plant species, our solutions help identify
            areas for improvement and implement appropriate measures to enhance
            plant productivity.
            <br />
            Our project focuses on the updating and development of olive
            cultivation lands and conducting a comprehensive inventory of their
            locations in Madaba, Jordan, on behalf of the Directorate of
            Agriculture. Through our expertise in GIS and engineering
            consultations, we aim to enhance land management, optimize land use
            efficiency, and evaluate the quality and health of the plant
            species. Partnering with us ensures the effective utilization of
            olive cultivation lands and contributes to the advancement of
            agricultural practices in Jordan.
          </h5>
        </div>
        <div>
          <h2 className="text-primary">
            3- updating maps for the Rusafa district in Iraq in cooperation with
            Al-Mustansiriyah university
          </h2>
          <h5>
            The primary objective of the project is to update maps, diagrams,
            and databases related to the Rusafa district. Our intelligent
            solutions enable us to deliver accurate and up-to-date information
            for decision-making. Additionally, we offer various engineering and
            urban planning consultations to optimize the development of the
            district.
          </h5>
          <h5>
            - Updated and Reliable Maps: Our project delivers accurate and
            updated maps of the Rusafa district, providing valuable information
            for urban planning, infrastructure development, and decision-making
            processes.
          </h5>
          <h5>
            - Efficient Database Management: Our advanced database management
            techniques ensure that the information related to the district is
            well-organized, easily accessible, and up-to-date, enabling
            efficient data analysis and retrieval.
          </h5>
          <h5>
            - Optimized Development: Through our engineering and urban planning
            consultations, we assist in optimizing the development of the Rusafa
            district, considering factors such as infrastructure, land use, and
            urban design, leading to sustainable and efficient growth.
          </h5>
          <h5>
            Our project focuses on the updating and development of maps for the
            Rusafa district in Iraq. Through our intelligent solutions, we
            provide accurate and up-to-date information, efficient database
            management, and comprehensive engineering and urban planning
            consultations. Partnering with us ensures the optimization of the
            district's development and facilitates informed decision-making.
          </h5>
        </div>
        <div>
          <div>
            <h2 className="text-primary">
              4- Innovative GIS Mobile Application for Red Palm Weevil Control
              in Siwa Oasis
            </h2>
            <h5>
              We are proud to present a groundbreaking GIS mobile application
              developed for Green Al-Yamama Company, aimed at monitoring and
              controlling the Red Palm Weevil infestation in Siwa Oasis. This
              project represents a major leap forward in the field of geospatial
              information systems and geomatics, as well as agricultural pest
              management. The Red Palm Weevil is a devastating pest that affects
              over 50% of palm trees worldwide. Our application provides
              real-time monitoring, early detection, and timely eradication
              measures, ensuring the complete elimination of this destructive
              pest.
            </h5>
            <h5>
              our GIS mobile application represents a significant breakthrough
              in geospatial technology and agricultural pest management. By
              effectively monitoring, detecting, and controlling the Red Palm
              Weevil infestation in Siwa Oasis, we contribute to the
              preservation of palm trees and the sustainable growth of the
              agricultural sector. For more information about this innovative
              solution, please contact us.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
