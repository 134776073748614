import img1 from "../images/Consultation Services.jpg";
import img2 from "../images/Cost Benefit Analysis.png";
import img3 from "../images/Geospatial Data Strategy.jpg";
import img4 from "../images/Needs Assessment.jpg";
import img5 from "../images/Solution Design and Architecture.jpg";
import img6 from "../images/System Integration.jpg";
import img7 from "../images/Training and Capacity Building.jpg";
import { Helmet } from "react-helmet";

export default function Service3() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Etqan Service </title>
        <link rel="canonical" href="https://etqan-egy.com/service" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img4}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">1-Needs Assessment:</h2>
            <p>
              {" "}
              We conduct a thorough needs assessment to understand our clients'
              specific requirements and challenges. Our team of experts engages
              with stakeholders to identify their goals, objectives, and pain
              points. Through this process, we gain a deep understanding of the
              client's business processes, workflows, and data requirements,
              enabling us to provide tailored solutions.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              2-Solution Design and Architecture:
            </h2>
            <p>
              {" "}
              Based on the needs assessment, we design customized geospatial
              solutions and system architectures. Our team of consultants works
              closely with clients to define the required functionalities, data
              models, and integration points. We consider scalability,
              performance, security, and usability factors to develop optimal
              solutions that align with the client's strategic goals.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img5}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img3}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">3. Geospatial Data Strategy:</h2>
            <p>
              {" "}
              We assist in developing a geospatial data strategy that
              encompasses data acquisition, management, integration, and
              dissemination. Our consultants help clients identify relevant data
              sources, define data quality standards, establish data governance
              frameworks, and devise effective data integration and sharing
              mechanisms. Our goal is to enable clients to make data-driven
              decisions and maximize the value of their geospatial data.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">4.System Integration:</h2>
            <p>
              {" "}
              We provide expertise in integrating geospatial systems with other
              enterprise systems, such as Customer Relationship Management
              (CRM), Enterprise Resource Planning (ERP), or Asset Management
              Systems. Our consultants design and implement seamless integration
              solutions, enabling the exchange of data and information between
              different systems, eliminating data silos, and improving data
              consistency.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img6}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img7}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">5.Training and Capacity Building:</h2>
            <p>
              {" "}
              We offer training programs and workshops to empower our clients
              with the necessary skills and knowledge to effectively use
              geospatial technologies. Our training sessions cover GIS
              fundamentals, data analysis techniques, system administration, and
              application development. We also provide ongoing support and
              guidance to build internal capacity within client organizations.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">6.Cost-Benefit Analysis:</h2>
            <p>
              {" "}
              We assist our clients in conducting cost-benefit analyses to
              evaluate the potential return on investment (ROI) of geospatial
              projects and technologies. Our consultants help quantify the
              benefits and assess the costs associated with implementing
              geospatial solutions. This analysis enables clients to make
              informed decisions about resource allocation and prioritize
              projects based on their potential impact.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img2}
              alt="markting"
            />
          </div>
        </div>
      </div>
    </>
  );
}
