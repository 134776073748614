import Footer from "./../Footer/Footer";
import { Outlet } from "react-router-dom";
import Home from "../Home/Home";
import Navbar from "../Navbar/Navbar";

export default function Layout() {
  return (
    <>
      <Navbar />

      <Outlet>
        <Home />
      </Outlet>
      <br />
      <Footer />
    </>
  );
}
