import { Helmet } from "react-helmet";
import AboutUSHome from "../AboutUsHome/AboutUSHome";
import ServicesHome from "../ServicesHome/ServicesHome";
import ClientsReviewHome from "../ClientsReviewHome/ClientsReviewHome";
import PartnersHome from "../PartnersHome/PartnersHome";
import OurTeamHome from "../OurTeamHome/OurTeamHome";
import ContactUsHome from "../ContactUsHome/ContactUsHome";

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Etqan GIS company </title>
        <link rel="canonical" href="https://etqan-egy.com/" />
      </Helmet>
      <div className="container">
        <AboutUSHome />
        <ServicesHome />
        <ClientsReviewHome />
        <PartnersHome />
        <OurTeamHome />
        <ContactUsHome />
      </div>
    </>
  );
}
