import React from "react";
import partner1 from "../images/Cost Benefit Analysis.png";
import partner2 from "../images/Geospatial Data Strategy.jpg";
import partner3 from "../images/Geospatial Database Design and Development2.jpg";
import partner4 from "../images/Stakeholder Management.jpg";

export default function ServicesHome() {
  return (
    <>
      <div className="container bg-light">
        <div className="   title text-center py-5">
          <h2 className="text-primary">Service</h2>
          {/* <h3 className="translate-middle">S</h3>
            <div className="afterr"></div> */}
          <br />
          <br />
          <h4 className="text-center px-3">
            Etqan for Geoinformatics Systems and Solutions is a leading
            specialized company that provides spatial systems and solutions,
            geospatial services, and geoinformatics.{" "}
          </h4>
          <h4>
            With a remarkable track record of major projects and collaborations
            with prominent international organizations.
          </h4>
          <h4>
            Etqan has established itself as a trusted partner in delivering
            innovative and sustainable geospatial solutions.
          </h4>
        </div>

        <div className="about-us section-padding">
          <div className="penta-">
            <div
              className="row justify-content-lg-between align-items-lg-center mb-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <div className="col-md-6 ">
                <div className="about-details text-center">
                  <h3 className="text-primary">Geoinformatics Data Services</h3>
                  <div className="about-img d-flex justify-content-center">
                    <img
                      className="img-fluid hide-title"
                      src="./assets/imgs/services-page/gis.png"
                      alt=""
                    />
                  </div>
                  <p className="h5">
                    In our specialized company dedicated to Geoinformatics, we
                    offer a comprehensive range of data services that leverage
                    the power of Geographic Information Systems (GIS) to provide
                    valuable insights and solutions for businesses and
                    organizations. Our Geoinformatics Data Services are designed
                    to handle geospatial data effectively, enabling our clients
                    to make informed decisions, optimize operations, and gain a
                    competitive edge in their respective industries.
                  </p>
                </div>
              </div>
              <div className="col-md-5 d-flex justify-content-center">
                <div className="about-img show-title">
                  <img className="img-fluid" src={partner1} alt="" />
                </div>
              </div>
            </div>

            <div
              className="row justify-content-lg-around flex-column-reverse flex-md-row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <div className="col-md-4 d-flex justify-content-center order-xs-2">
                <div className="about-img show-title ">
                  <img className="img-fluid" src={partner4} alt="" />
                </div>
              </div>
              <div className="col-md-6  d-flex align-items-center order-xs-1">
                <div className="about-details ">
                  <h3 className="text-primary">Project Management Services </h3>
                  <div className="about-img   d-flex justify-content-center">
                    <img
                      className="img-fluid hide-title"
                      src="./assets/imgs/services-page/training.png"
                      alt=""
                    />
                  </div>
                  <p className="h5">
                    In our specialized company focused on Geoinformatics, we
                    offer a comprehensive range of project management services
                    tailored to the field of Geographic Information Systems
                    (GIS). Our project management services ensure the successful
                    planning, execution, and completion of geospatial projects,
                    allowing our clients to achieve their objectives efficiently
                    and effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="penta">
            <div
              className="row justify-content-lg-between align-items-lg-center mb-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <div className="col-md-6 text-center ">
                <div className="about-details">
                  <h3 className="text-primary">Consultation Services</h3>
                  <div className="about-img d-flex justify-content-center">
                    <img
                      className="img-fluid hide-title"
                      src="./assets/imgs/services-page/gis.png"
                      alt=""
                    />
                  </div>
                  <p className="h5">
                    In our specialized company focused on Geoinformatics, we
                    provide comprehensive consultation services to assist our
                    clients in leveraging the power of Geographic Information
                    Systems (GIS) and geospatial technologies. Our consultation
                    services aim to address specific challenges, provide
                    strategic guidance, and unlock the full potential of
                    geospatial data and systems.
                  </p>
                </div>
              </div>
              <div className="col-md-5 d-flex justify-content-center">
                <div className="about-img show-title">
                  <img className="img-fluid" src={partner2} alt="" />
                </div>
              </div>
            </div>

            <div
              className="row justify-content-lg-around flex-column-reverse flex-md-row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <div className="col-md-4 d-flex justify-content-center order-xs-2">
                <div className="about-img show-title ">
                  <img className="img-fluid" src={partner3} alt="" />
                </div>
              </div>
              <div className="col-md-6  d-flex align-items-center order-xs-1">
                <div className="about-details ">
                  <h3 className="text-primary">
                    GIS Applications Development and Solutions{" "}
                  </h3>
                  <div className="about-img   d-flex justify-content-center">
                    <img
                      className="img-fluid hide-title"
                      src="./assets/imgs/services-page/training.png"
                      alt=""
                    />
                  </div>
                  <p className="h5">
                    In our specialized company focused on Geoinformatics, we
                    offer a wide range of services and solutions in GIS
                    applications development. We leverage our expertise in
                    Geographic Information Systems (GIS) to create customized
                    geospatial solutions that cater to our clients' specific
                    needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
