import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/colors.png";
import Headers from "../Headers/Headers";
import video from "../videos/2.mp4";
import VideoPlayer from "../Videos.js/Videos";
import Typed from "typed.js";

export default function Navbar() {
  const el = React.useRef(null);
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Geoinformatics Systems for a Dynamic World Power Your Vision with Etqan",
      ],
      typeSpeed: 50,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <>
      <nav class="navbar navbar-expand-lg fixed-top  ">
        <div className="container">
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item ms-auto">
              <Link className="navbar-brand  " to="/">
                {" "}
                <img
                  className=" w-100 "
                  style={{ height: "50px" }}
                  src={logo}
                  alt="logo"
                />{" "}
              </Link>
            </li>
            {/* <li className="nav-item d-flex  ">
    <Link className="navbar-brand align-items-center justify-content-center  " to="/"> <h5>Etqaan</h5> </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        
      </button>

    </li> */}
          </ul>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link nav-menu:hover " to="home">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link .nav-menu:hover" to="aboutus">
                  About Us
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  class="nav-link dropdown-toggle"
                  to=""
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item text-black" to="Service1">
                      Geoinformatics Data Services
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item text-black" to="Service2">
                      Project Management Services
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item text-black" to="Service3">
                      Consultation Services
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item text-black" to="Service4">
                      GIS Applications Development and Solutions
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link .nav-menu:hover" to="projects">
                  Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link .nav-menu:hover" to="trainings">
                  Trainings
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link .nav-menu:hover" to="partners">
                  Partners
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="bbb">
        <header id="header" class="">
          <video
            style={{ width: "100%", height: "100%" }}
            className="background-video"
            playsInline
            loop
            autoPlay
            muted
            src={video}
          ></video>

          <div class="head vh-100 d-flex justify-content-center align-items-center ">
            <div class="header-content text-center text-white w-50">
              <h3 class="mt-4 fs-3">
                <span className="h1" ref={el} />
              </h3>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
{
  /* <h1 class="mt-4">Etqan Company</h1>
              <h6 class="mt-4 fs-3">
                We're the biggest, best at GIS Projects.
              </h6> */
}
{
  /* <VideoPlayer
            videosrc={video}
            width={"100%"}
            height={"100%"}
          ></VideoPlayer> */
}
