import img1 from "../images/Geoinformatics Data Services/Data Collection and Acquisition.png";
import img2 from "../images/Geoinformatics Data Services/Data integration and Managment.jpg";
import img3 from "../images/Geoinformatics Data Services/Spatial Analysis and Modeling.jpg";
import img4 from "../images/Geoinformatics Data Services/Geodatabase Design and Development.png";
import img5 from "../images/Geoinformatics Data Services/Web-based GIS Solutions.png";
import img6 from "../images/Geoinformatics Data Services/Data Visualization and Cartography.jpg";
import img7 from "../images/Geoinformatics Data Services/Quality Assurance and Data Validation.png";
import { Helmet } from "react-helmet";
export default function Service1() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Etqan Service </title>
        <link rel="canonical" href="https://etqan-egy.com/service" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img1}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">1-Data Collection and Acquisition:</h2>
            <p>
              {" "}
              We provide data collection and acquisition services using various
              methods, including satellite imagery, aerial surveys, LiDAR (Light
              Detection and Ranging), and ground-based data collection
              techniques. Our team ensures the accuracy and reliability of the
              collected data, which serves as the foundation for subsequent
              analysis and decision-making processes.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">2-Data Integration and Management:</h2>
            <p>
              {" "}
              Our experts are proficient in integrating diverse datasets from
              different sources, including spatial and non-spatial data. By
              combining geospatial information with other relevant data types,
              such as demographics, climate data, or business data, we create
              comprehensive and enriched datasets. We also offer data management
              solutions to organize, store, and maintain geospatial data
              efficiently.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img2}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img3}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">3-Spatial Analysis and Modeling:</h2>
            <p>
              {" "}
              Using advanced spatial analysis techniques, we help clients gain
              valuable insights from their geospatial data. We perform various
              analyses, such as spatial clustering, spatial interpolation,
              network analysis, and suitability modeling, to identify patterns,
              relationships, and trends. These analyses support decisionmaking
              processes, resource allocation, risk assessment, and optimization
              of spatial operation
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              4-Geodatabase Design and Development:
            </h2>
            <p>
              {" "}
              Our Geoinformatics Data Services include designing and developing
              geodatabases tailored to our clients' specific needs. A
              well-structured geodatabase allows for efficient data storage,
              retrieval, and analysis. We ensure data integrity, spatial
              relationships, and optimal performance by implementing appropriate
              data models and database management systems.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img4}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img5}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">5-Web-based GIS Solutions:</h2>
            <p>
              {" "}
              We specialize in developing web-based GIS applications and portals
              that provide easy access to geospatial data, maps, and analytical
              tools. These solutions enable our clients to share information,
              collaborate, and make geospatial data available to a wider
              audience within their organization or to the public. Our web-based
              GIS solutions are user-friendly, interactive, and customizable to
              meet specific requirements.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              6-Data Visualization and Cartography:
            </h2>
            <p>
              {" "}
              We offer data visualization and cartography services to transform
              complex geospatial data into visually appealing and informative
              maps, charts, and infographics. Our experts employ advanced
              cartographic techniques to present data in a clear and intuitive
              manner, facilitating data interpretation and communication. We
              ensure that the visual representations align with our clients'
              branding and messaging.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img6}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img7}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              7-Quality Assurance and Data Validation:
            </h2>
            <p>
              {" "}
              Data quality is crucial for accurate analysis and decision-making.
              Therefore, we provide quality assurance and data validation
              services to ensure the accuracy, completeness, consistency, and
              reliability of geospatial data. Our experts perform data quality
              checks, identify errors or inconsistencies, and implement
              validation processes to maintain data integrity.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
