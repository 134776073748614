import React from "react";

export default function Footer() {
  return (
    <>
      <div  className="footer  w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <p><i class="fa-solid fa-envelope "></i> Info@etqanco.com</p>
              <p><i class="fa-solid fa-location-dot  "></i> Zahraa Al Maadi,Cairo Governorate</p>
              <p>
                <i class="fa-solid fa-phone "></i>02 23259050
              </p>
            </div>
            <div className="col-md-9 d-flex flex-column justify-content-center">
              <div className="social-media">
                <a href="https://www.facebook.com/profile.php?id=61555488058832&locale=ar_AR">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fa-brands fa-google"></i>
                </a>
                <a href="https://www.linkedin.com/company/101457916/admin/feed/posts/">
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
              <p>
                Copy Right 2024 © By <a href="#">Etqaan-egy</a> All Rights
                Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
