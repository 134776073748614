import React from "react";
// import p11 from "../images/p11.jpg";
// import p12 from "../images/p12.png";
// import p13 from "../images/p13.png";
// import p14 from "../images/p14.png";
// import p15 from "../images/p15.png";
// import p16 from "../images/p16.png";
// import p17 from "../images/p17.jpg";
// import p18 from "../images/p18.png";
// import p19 from "../images/p19.png";
// import p20 from "../images/p20.png";
// import p21 from "../images/p21.png";
// import p22 from "../images/p22.png";
// import p23 from "../images/partner2.jpeg";
import p21 from "../images/p25.jpg";
import p22 from "../images/p24.png";
import p23 from "../images/partner2.jpeg";

import { Helmet } from "react-helmet";

export default function Partners() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Etqan GIS company Partners </title>
        <link rel="canonical" href="https://etqan-egy.com/partners" />
      </Helmet>
      <div className="container">
        <div className="countainer">
          <div className="countainer">
            <div className="row">
              <div className=" text-center col-md-8 p-4">
                <br />
                <h2 className="text-primary">GIG</h2>
                <p>
                  Globe imagine for Geoinformatics (GIG) company is mainly
                  concerned with the innovative geospatial solutions that are
                  integrated with new innovative technologies
                </p>
              </div>
              <div className="col-md-4 p-4 ">
                <img
                  className="w-100"
                  style={{ height: 350 }}
                  src={p23}
                  alt="markting"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="countainer">
          <div className="countainer">
            <div className="row">
              <div className="col-md-4 p-4 ">
                <img
                  className="w-100"
                  style={{ height: 350 }}
                  src={p21}
                  alt="markting"
                />
              </div>
              <div className=" text-center col-md-8 p-4">
                <br />
                <h2 className="text-primary">
                  The Tunisian Association of the Digital Geographic Information{" "}
                </h2>
                <p>
                  The Tunisian Association for Digital Geographic Information a
                  scientific association founded in Tunisia in 2005. It is an
                  organization of cooperative union among many specialists in
                  this field for the sake of expertise and knowledge, continuous
                  research in digital geography technologies, and encouraging
                  cooperation in various fields such as urban planning,
                  environment, agriculture, natural resource management, and
                  infrastructure. The association organizes workshops,
                  conferences, and courses to enhance skills and knowledge in
                  this field. It also spreads awareness about the importance of
                  geospatial technologies and their various applications in
                  improving the quality of life and addressing essential needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="countainer">
          <div className="countainer">
            <div className="row">
              <div className=" text-center col-md-8 p-4">
                <br />
                <h2 className="text-primary">
                  El-Abd Egyptian Contracting Company
                </h2>
                <p>
                  Al-Abd company specializing in emerging contracting is
                  considered one of the leading companies in the field of
                  contracting, as evidenced by its long track record of
                  experience since the establishment of the company. It
                  effectively influences the development of the national economy
                  within the framework of public policy, as it implements giant
                  industrial projects.
                </p>
              </div>
              <div className="col-md-4 p-4 ">
                <img
                  className="w-100"
                  style={{ height: 350 }}
                  src={p22}
                  alt="markting"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
