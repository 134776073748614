import React from "react";
import cl1 from "../images/client2.jpeg";
import cl2 from "../images/client4.jpg";
import cl3 from "../images/loay.jpg";
import cl4 from "../images/khaled.jpg";

export default function ClientsReviewHome() {
  return (
    <>
      <div className="container">
        <div className="row">
          <h2 className="text-primary text-center ">Clients Review</h2>
          <div className="col-md-6 d-flex">
            <img className="w-50 " src={cl1} alt="" />
            <img className="w-50 " src={cl2} alt="" />
          </div>
          {/* <div className="clearfix"></div> */}
          <div className="col-md-6 text-center ">
            <img className="w-50" src={cl3} alt="" />
            <h3>Loay Monser</h3>
            <h5>
              the Executive Director of Muhammad Ali Ziyad General Contracting
              Company, responsible for overseeing the project to develop water
              and sanitation networks in cooperation with UNICEF
            </h5>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="text-center">
          <h4>
            '' One of the most significant aspects we have observed in our
            collaboration with Itqan is their commitment to integrating diverse
            software environments and striving for excellence and
            distinctiveness in delivering innovative solutions in geographic
            information systems characterized by speed, accuracy, and
            creativity.''
          </h4>
        </div>
        <br />
        <div className="line"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img className="w-50" src={cl4} alt="" />
              <h3>Khaled El Hassi</h3>
              <h4>
                General Manager of LIBYA Benghazi-Meammar Arabi for Construction
                Co.
              </h4>
            </div>
            <div className="col-md-6">
              <h4>
                The cooperation displayed by Etqan throughout the project,
                combined with their unique ideas, has fostered a strong bond
                between our organizations. We look forward to future
                collaborations with Etqan, given their exceptional expertise in
                geographic information systems and software development, as well
                as their innovative solutions. Etqan's distinctive cooperation
                and original ideas were evident throughout the project, further
                cementing our partnership.
              </h4>
              <h4>
                We anticipate further opportunities for collaboration in the
                near future, leveraging Etqan's distinctive technologies in the
                field of geographic information systems and software
                development, along with their innovative solutions.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
