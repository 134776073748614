import React from "react";
import p1 from "../images/WhatsApp Image 2024-01-14 at 10.43.37 AM.jpeg";
import { Helmet } from "react-helmet";
import logo from "../images/colors.png";

export default function AboutUs() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Etqan GIS company </title>
        <link rel="canonical" href="https://etqan-egy.com/aboutus" />
      </Helmet>
      <br />
      <br />

      <div className="container">
        <div className="row ">
          <div className="col-md-5 p-4 d-flex">
            <img
              className="w-100 align-content-center justify-content-center "
              style={{ height: 420 }}
              src={logo}
              alt="markting"
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 p-4">
            <h3 className="text-primary">Who we are?</h3>
            <p>
              Etqan for Geoinformatics Systems and Solutions is a leading
              specialized company that provides spatial systems and solutions,
              geospatial services, and geoinformatics.
            </p>
            <div className="about-p">
              <p>
                The company has a remarkable track record of major projects and
                collaborations with prominent international organizations.
              </p>
              <p>
                Etqan has previously collaborated with UNICEF in Yemen, where it
                delivered innovative spatial solutions and geospatial
                consultations in several projects, including areas like 22 May
                and Al-Salakhana, among others.
              </p>
              <p>
                The company also possesses extensive experience in planning
                residential compounds and providing suitable spatial solutions
                for them.
              </p>
              <p>
                Etqan has a portfolio of successful projects and wide-ranging
                expertise in the field of spatial solutions and consultations.
              </p>
              <p>
                The company aims to make a positive impact on the global and
                local market for geographic information systems by developing
                spatial solutions and related software.
              </p>
              <p>
                It strives for excellence and innovation in this field,
                providing innovative and advanced solutions to meet clients'
                needs and achieve their utmost satisfaction.
              </p>
              <p>
                The company prides itself on having a qualified and specialized
                team in geospatial and geoinformatics committed to delivering
                high-quality services tailored to clients' requirements. Etqan
                aims to enhance collaboration with clients and partners, achieve
                mutual success, and improve spatial operations and business
                strategies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
