import React from "react";
import { Helmet } from "react-helmet";

export default function Trainings() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Etqan Trainings </title>
        <link rel="canonical" href="https://etqan-egy.com/" />
      </Helmet>
      <br />
      <br />

      <div className="container">
        <h2 className="text-primary"> Training :</h2>

        <h4>
          Comprehensive Geospatial Training Courses: Unleashing the Power of
          Geographic Information Systems
        </h4>

        <p>
          Welcome to our Academy , where we offer a wide range of highly
          advanced and foundational courses in the field of Geographic
          Information Systems (GIS), databases, and the integration of these
          tracks . Our training programs cater to individuals and organizations
          seeking to enhance their expertise in geospatial technologies. We
          specialize in open-source solutions, providing participants with the
          knowledge and skills to leverage the full potential of these tools.
        </p>
        <h2 className="text-primary"> Our Academy features:</h2>
        <h4>
          1. Diverse Course Selection: Our training catalog includes a variety
          of essential and highly advanced courses in GIS, databases, and the
          intersection between the two. From introductory courses covering
          fundamental concepts to advanced topics such as spatial analysis, web
          mapping, and database integration, we provide a comprehensive learning
          experience for learners at all levels.
        </h4>

        <h4>
          2. Open-Source Focus: We believe in the power of open-source
          geospatial solutions. Our courses are designed to familiarize
          participants with popular open-source tools and platforms such as
          QGIS, PostgreSQL, and PostGIS. By utilizing these technologies,
          organizations can benefit from cost-effective and flexible geospatial
          solutions.
        </h4>
        <h4>
          3. Expert Instructors and Practical Training: Our instructors are
          experienced professionals who bring real-world expertise into the
          classroom. They provide hands-on training, practical exercises, and
          real-life case studies, ensuring that participants gain practical
          skills and can immediately apply what they learn to real-world
          scenarios.
        </h4>
        <br />
        <h2 className="text-primary">Our Tracks : </h2>
        <h4>
          <h4>- Introduction to Open-Source GIS and Database Integration</h4>
          <h4>- Spatial Analysis using QGIS and PostgreSQL</h4>
          <h4>- Web Mapping with Open-Source Technologies</h4>
          <h4>- Advanced Database Management with PostGIS</h4>
          <h4>- Geospatial Data Visualization</h4>
        </h4>
        <h2 className="text-primary">Benefits & Returns :</h2>
        <h4>
          - Enhanced Skills and Competence: By enrolling in our comprehensive
          training courses, participants will acquire the knowledge and skills
          necessary to effectively utilize open-source geospatial tools and
          databases. This expertise will enable them to analyze spatial data,
          create interactive maps, and integrate geospatial data with databases,
          empowering them to make informed decisions.
        </h4>

        <h4>
          - Cost-Effective Solutions: Open-source geospatial technologies offer
          cost-effective alternatives to proprietary software. Our training
          courses focus on maximizing the potential of these tools, enabling
          organizations to leverage open-source solutions and reduce licensing
          costs.
        </h4>
        <h4>
          - Practical Applications and Innovation: Through practical training
          and hands-on exercises, participants will gain the ability to apply
          their knowledge to real-world projects. This practical experience
          fosters innovation and enables professionals to develop creative
          solutions to geospatial challenges.
        </h4>
        <h4>
          Join us and explore our range of comprehensive geospatial training
          courses. Unlock the power of open-source GIS and database integration,
          and propel your organization to new heights in the world of geospatial
          technologies. For more information, please contact us
        </h4>
      </div>
    </>
  );
}
