import img1 from "../images/GIS Applications Development and Solutions/Requirements Gathering and Analysis.png";
import img2 from "../images/GIS Applications Development and Solutions/Custom GIS Application Development.png";
import img3 from "../images/GIS Applications Development and Solutions/Geospatial Database Design and Development.png";
import img4 from "../images/GIS Applications Development and Solutions/Data Integration and Visualization.png";
import img5 from "../images/GIS Applications Development and Solutions/Mobile GIS Applications.png";
import img6 from "../images/GIS Applications Development and Solutions/GIS Web Services and APIs.jpg";
import img7 from "../images/GIS Applications Development and Solutions/Maintenance and Support in GIS.png";
import { Helmet } from "react-helmet";

export default function Service4() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Etqan Service </title>
        <link rel="canonical" href="https://etqan-egy.com/service" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img1}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              1-Requirements Gathering and Analysis:
            </h2>
            <p>
              {" "}
              We work closely with our clients to understand their unique
              requirements and objectives for GIS applications. Our team of
              experts conducts thorough requirements gathering and analysis to
              identify the functionalities, data requirements, and user
              experience expectations. This helps us ensure that the developed
              GIS applications meet the specific needs of our clients.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              2-Custom GIS Application Development:
            </h2>
            <p>
              {" "}
              Based on the requirements analysis, we design and develop
              customized GIS applications to address specific challenges or
              leverage geospatial data for decision-making. Our development team
              utilizes industry-leading tools and technologies to create robust,
              scalable, and user-friendly GIS applications. These applications
              can range from web-based mapping portals to mobile applications or
              desktop software
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img2}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img3}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              3- Geospatial Database Design and Development:
            </h2>
            <p>
              {" "}
              We design and develop geospatial databases that serve as the
              foundation for GIS applications. Our team follows best practices
              in database design to ensure efficient data storage, retrieval,
              and management. We incorporate spatial indexing, data
              normalization, and schema design to optimize the performance and
              usability of the geospatial databases.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">
              4-Data Integration and Visualization:
            </h2>
            <p>
              {" "}
              We specialize in integrating various data sources into GIS
              applications. Our experts work with different data formats, such
              as shapefiles, geodatabases, satellite imagery, and real-time
              sensor data, to integrate them seamlessly into the GIS
              applications. We also provide advanced data visualization
              capabilities, allowing users to explore and analyze geospatial
              data through maps, charts, and graphs.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img4}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img5}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">5-Mobile GIS Applications:</h2>
            <p>
              {" "}
              We develop mobile GIS applications that enable users to access
              geospatial data and perform GIS functions on mobile devices such
              as smartphones and tablets. These applications provide real-time
              data collection, field mapping, navigation, and data
              synchronization capabilities. Mobile GIS applications empower
              field workers to capture and analyze data in the field, improving
              data accuracy and efficiency.
            </p>
          </div>

          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">6.GIS Web Services and APIs:</h2>
            <p>
              {" "}
              We provide GIS web services and APIs (Application Programming
              Interfaces) that allow seamless integration of GIS functionality
              into existing systems or third-party applications. These services
              and APIs enable developers to access geospatial data, perform
              spatial queries, and visualize maps within their applications.
              They facilitate interoperability and enhance the capabilities of
              other software systems.
            </p>
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img6}
              alt="markting"
            />
          </div>
          <div className="col-md-4 p-4 ">
            <img
              className="w-100"
              style={{ height: 350 }}
              src={img7}
              alt="markting"
            />
          </div>
          <div className=" text-center  col-md-8 p-4">
            <br />
            <h2 className="text-primary">7.Maintenance and Support:</h2>
            <p>
              {" "}
              We provide ongoing maintenance and support services to ensure the
              smooth operation of GIS applications. Our team offers bug fixing,
              performance optimization, and updates to keep the applications
              up-to-date with the latest technologies and security patches. We
              also provide user support, training, and documentation to assist
              clients in effectively using and managing the GIS applications.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
